@tailwind base;
@tailwind components;
@tailwind utilities;

.custom-gradient-bg {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgb(32, 0, 36) 0%,
    rgba(9, 9, 121, 1) 4%,
    rgb(92, 0, 116) 74%
  );
  background-size: 600% 600%;
}

.custom-image-bg  {
  background-image: url('./images/Without_Logo.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh; 
  width: 100%; 
  display: flex;
  flex-direction: column;
}

.vextorial-desc-text-outline {
  text-shadow:
    -5px -5px 0 #000,
    5px -5px 0 #000,
    -5px 5px 0 #000,
    5px 5px 0 #000;
}

.custom-size {
  height: 250px;
  width: auto;
}

.smooth-scroll {
  scroll-behavior: smooth;
}

.floating-button {
  z-index: 9999;
}

.speech-container {
  opacity: 0.5; 
  transition: opacity 0.3s; 
}

.speech-container:hover {
  opacity: 1; 
}

.text-outline {
  text-shadow: -2px -2px 0 #ff00ff, 2px -2px 0 #ff00ff, -2px 2px 0 #ff00ff,
    2px 2px 0 #ff00ff, -2px 0px 0 #ff00ff, 2px 0px 0 #ff00ff, 0px -2px 0 #ff00ff,
    0px 2px 0 #ff00ff;
}

.neon-text {
  animation: neonPulse 10s ease-in-out infinite alternate;
}

@keyframes neonPulse {
  0% {
    text-shadow: -2px -2px 5px #ff00ff, 2px -2px 5px #ff00ff,
      -2px 2px 5px #ff00ff, 2px 2px 5px #ff00ff, -2px 0px 5px #ff00ff,
      2px 0px 5px #ff00ff, 0px -2px 5px #ff00ff, 0px 2px 5px #ff00ff;
  }
  50% {
    text-shadow: -2px -2px 10px #ff00ff, 2px -2px 10px #ff00ff,
      -2px 2px 10px #ff00ff, 2px 2px 10px #ff00ff, -2px 0px 10px #ff00ff,
      2px 0px 10px #ff00ff, 0px -2px 10px #ff00ff, 0px 2px 10px #ff00ff;
  }
  100% {
    text-shadow: -2px -2px 5px #ff00ff, 2px -2px 5px #ff00ff,
      -2px 2px 5px #ff00ff, 2px 2px 5px #ff00ff, -2px 0px 5px #ff00ff,
      2px 0px 5px #ff00ff, 0px -2px 5px #ff00ff, 0px 2px 5px #ff00ff;
  }
}

.speech {
  font-size: 1.2em;
  color: #fff;
  background: #91199c;
  padding: 20px;
  border-radius: 10px;
  width: 150px;
  max-width: 150px;
}
.speech::after {
  display: block; width: 0; content: "";
  border: 15px solid transparent;
}
.speech.down::after {
  border-top-color: #91199c;
  border-bottom: 0;
}
.speech::after { position: absolute; }
.speech.down::after {
  bottom: -10px; left: calc(80% - 15px);
}

.custom-neon-border {
  position: relative;
}

.custom-neon-border::before {
  content: '';
  position: absolute;
  top: -8px;
  left: -8px;
  right: -8px;
  bottom: -8px;
  border: 8px solid transparent;
  border-radius: inherit;
  box-shadow: 0 0 10px 2px rgba(127, 229, 255, 0.8), 0 0 20px 4px rgba(13, 199, 255, 0.6), 0 0 30px 6px rgba(3, 154, 241, 0.4);
  z-index: -1;
}

.cube-wrap {
  --size: 100vmin;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  perspective: 100vmin;
}
.cube {
  transform-style: preserve-3d;
  transform: rotateX(0deg) rotateZ(0eg) rotateY(0deg);
  animation: cube 1s linear;
}
@keyframes cube {
  to {
    transform: rotateX(0deg) rotateZ(0deg) rotateY(360deg);
  }
}

.cube-dist {
  margin-right: 100px;
}

.cube-opp {
  transform-style: preserve-3d;
  transform: rotateX(0deg) rotateZ(0eg) rotateY(100deg);
  animation: cube-opp 1s linear;
}
@keyframes cube-opp {
  to {
    transform: rotateX(0deg) rotateZ(0deg) rotateY(-360deg);
  }
}

.side {
  position: absolute;
  width: var(--size);
  height: var(--size);
  background-color: #eee;
  backface-visibility: visible;
  top: calc(var(--size) * -.5);
  left: calc(var(--size) * -.5);
}
.top {
  background-color: #fff;
  transform: rotateX(90deg) translateZ(calc(var(--size) * .5));
}
.bottom {
  background-color: #999;
  transform: rotateX(90deg) translateZ(calc(var(--size) * -.5));
}
.left {
  background-color: #ccc;
  transform: rotateY(90deg) translateZ(calc(var(--size) * .5));
}
.right {
  background-color: #ddd;
  transform: rotateY(90deg) translateZ(calc(var(--size) * -.5));
}
.front {
  background-color: #aaa;
  transform: translateZ(calc(var(--size) * .5));
}
.back {
  background-color: #bbb;
  transform: translateZ(calc(var(--size) * -.5));
}

:root .cube,
:root .cube-opp,
:root .cube-opp *, 
:root .cube * {
  animation-play-state: paused;
  animation-delay: calc(var(--scroll) * -1s);
  animation-iteration-count: 1;
  animation-fill-mode: both;
}


#stars {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 1144px 728px #fff, 682px 735px #fff, 765px 1342px #fff,
    1098px 1189px #fff, 878px 1230px #fff, 754px 888px #fff, 1778px 1646px #fff,
    126px 1127px #fff, 1485px 23px #fff, 192px 1415px #fff, 488px 1489px #fff,
    539px 1578px #fff, 1720px 1531px #fff, 1031px 117px #fff, 1885px 1961px #fff,
    1212px 494px #fff, 149px 1298px #fff, 186px 1879px #fff, 914px 959px #fff,
    1948px 1384px #fff, 188px 1852px #fff, 1128px 1746px #fff,
    1486px 1885px #fff, 1608px 1075px #fff, 1252px 137px #fff, 270px 878px #fff,
    1802px 1559px #fff, 25px 1911px #fff, 1381px 293px #fff, 785px 357px #fff,
    1398px 1156px #fff, 1656px 338px #fff, 1551px 150px #fff, 1224px 536px #fff,
    585px 478px #fff, 1252px 1947px #fff, 1735px 365px #fff, 1671px 1871px #fff,
    1444px 1815px #fff, 1955px 1532px #fff, 1927px 168px #fff,
    1620px 1912px #fff, 1402px 1489px #fff, 54px 1247px #fff, 1143px 1189px #fff,
    698px 172px #fff, 1623px 1503px #fff, 485px 1533px #fff, 1560px 784px #fff,
    517px 950px #fff, 557px 201px #fff, 1959px 1370px #fff, 1744px 441px #fff,
    1678px 1171px #fff, 563px 875px #fff, 1013px 1615px #fff, 1018px 1582px #fff,
    1451px 20px #fff, 1526px 953px #fff, 1914px 1044px #fff, 1112px 48px #fff,
    1166px 64px #fff, 66px 1938px #fff, 1486px 998px #fff, 1759px 1964px #fff,
    1037px 1828px #fff, 465px 758px #fff, 1212px 176px #fff, 707px 157px #fff,
    1654px 1373px #fff, 1809px 334px #fff, 245px 1538px #fff, 1715px 462px #fff,
    1154px 1697px #fff, 1243px 331px #fff, 397px 861px #fff, 108px 1501px #fff,
    874px 133px #fff, 1822px 34px #fff, 1107px 1417px #fff, 143px 1763px #fff,
    1837px 1358px #fff, 318px 1139px #fff, 397px 1747px #fff, 1010px 772px #fff,
    92px 321px #fff, 1121px 183px #fff, 765px 1658px #fff, 1139px 52px #fff,
    1768px 1431px #fff, 358px 699px #fff, 583px 148px #fff, 375px 1002px #fff,
    1276px 231px #fff, 784px 214px #fff, 578px 944px #fff, 1135px 1543px #fff,
    334px 651px #fff, 1554px 889px #fff, 167px 1716px #fff, 1961px 688px #fff,
    986px 1801px #fff, 1994px 1859px #fff, 1631px 800px #fff, 435px 238px #fff,
    1707px 128px #fff, 1018px 612px #fff, 964px 1357px #fff, 96px 1730px #fff,
    433px 428px #fff, 950px 1456px #fff, 494px 885px #fff, 916px 1578px #fff,
    1547px 404px #fff, 1806px 1533px #fff, 1891px 166px #fff, 1761px 986px #fff,
    1093px 839px #fff, 112px 577px #fff, 188px 957px #fff, 423px 400px #fff,
    1949px 1424px #fff, 1502px 230px #fff, 832px 632px #fff, 690px 1107px #fff,
    1054px 1296px #fff, 1407px 1882px #fff, 1330px 1142px #fff, 689px 964px #fff,
    752px 434px #fff, 761px 1222px #fff, 1968px 46px #fff, 1449px 101px #fff,
    936px 832px #fff, 593px 1074px #fff, 1238px 729px #fff, 329px 1630px #fff,
    1609px 1742px #fff, 1613px 1335px #fff, 1187px 1238px #fff,
    1827px 1116px #fff, 720px 1162px #fff, 1795px 1548px #fff,
    1795px 1768px #fff, 600px 1144px #fff, 1799px 935px #fff, 887px 1814px #fff,
    1525px 1307px #fff, 1252px 134px #fff, 314px 68px #fff, 1057px 634px #fff,
    841px 1673px #fff, 1677px 923px #fff, 724px 1006px #fff, 449px 1909px #fff,
    1561px 733px #fff, 787px 460px #fff, 952px 1574px #fff, 965px 1854px #fff,
    12px 1819px #fff, 1771px 509px #fff, 998px 1745px #fff, 1158px 1713px #fff,
    287px 425px #fff, 1097px 13px #fff, 475px 1837px #fff, 1950px 1870px #fff,
    923px 310px #fff, 1883px 1308px #fff, 148px 1811px #fff, 1974px 878px #fff,
    371px 720px #fff, 201px 1137px #fff, 1069px 1619px #fff, 573px 874px #fff,
    1105px 1215px #fff, 1143px 750px #fff, 1356px 1999px #fff, 695px 1271px #fff,
    560px 1585px #fff, 562px 1185px #fff, 876px 888px #fff, 1124px 11px #fff,
    1127px 1512px #fff, 1615px 361px #fff, 1032px 295px #fff, 1100px 652px #fff,
    1768px 747px #fff, 1952px 288px #fff, 866px 1392px #fff, 1517px 1538px #fff,
    544px 1270px #fff, 645px 679px #fff, 1499px 662px #fff, 1046px 982px #fff,
    54px 883px #fff, 1583px 381px #fff, 1076px 944px #fff, 65px 1077px #fff,
    251px 1478px #fff, 518px 1936px #fff, 72px 1112px #fff, 1906px 1663px #fff,
    996px 442px #fff, 1330px 419px #fff, 1157px 1529px #fff, 1476px 1037px #fff,
    855px 83px #fff, 808px 399px #fff, 1952px 1680px #fff, 445px 84px #fff,
    977px 739px #fff, 1866px 458px #fff, 230px 293px #fff, 1414px 555px #fff,
    292px 989px #fff, 1651px 1518px #fff, 1362px 1511px #fff, 471px 862px #fff,
    383px 993px #fff, 685px 546px #fff, 1908px 1431px #fff, 1030px 107px #fff,
    523px 141px #fff, 1734px 1409px #fff, 824px 991px #fff, 747px 1326px #fff,
    1259px 1334px #fff, 805px 760px #fff, 308px 1130px #fff, 1511px 729px #fff,
    137px 725px #fff, 1551px 893px #fff, 1177px 154px #fff, 1846px 1661px #fff,
    484px 1096px #fff, 1691px 1521px #fff, 1598px 1910px #fff, 846px 468px #fff,
    1739px 1771px #fff, 893px 945px #fff, 831px 407px #fff, 404px 310px #fff,
    232px 1317px #fff, 1236px 1696px #fff, 1771px 457px #fff, 407px 1169px #fff,
    74px 1125px #fff, 1376px 1865px #fff, 44px 836px #fff, 1948px 595px #fff,
    188px 1258px #fff, 35px 1501px #fff, 629px 1069px #fff, 1603px 931px #fff,
    489px 862px #fff, 1181px 1986px #fff, 1866px 1444px #fff, 1537px 303px #fff,
    1952px 17px #fff, 13px 1240px #fff, 1307px 911px #fff, 1543px 1135px #fff,
    1101px 1278px #fff, 1212px 1077px #fff, 1798px 1155px #fff,
    1464px 342px #fff, 553px 1623px #fff, 207px 395px #fff, 683px 424px #fff,
    586px 1770px #fff, 1265px 220px #fff, 357px 1815px #fff, 780px 1184px #fff,
    877px 1904px #fff, 326px 1569px #fff, 1819px 1938px #fff, 1839px 503px #fff,
    720px 1231px #fff, 640px 1084px #fff, 1122px 657px #fff, 725px 945px #fff,
    992px 816px #fff, 1935px 851px #fff, 1074px 1686px #fff, 1160px 1438px #fff,
    1259px 1905px #fff, 1120px 215px #fff, 92px 581px #fff, 932px 367px #fff,
    1121px 523px #fff, 1273px 1866px #fff, 149px 140px #fff, 230px 1212px #fff,
    1265px 1592px #fff, 1223px 654px #fff, 820px 1316px #fff, 1369px 1946px #fff,
    319px 1164px #fff, 1058px 1973px #fff, 1756px 41px #fff, 740px 2000px #fff,
    1241px 1914px #fff, 248px 171px #fff, 1657px 1915px #fff, 1662px 260px #fff,
    1670px 736px #fff, 1877px 607px #fff, 396px 1058px #fff, 240px 599px #fff,
    769px 1041px #fff, 940px 1436px #fff, 793px 632px #fff, 1350px 430px #fff,
    1790px 1327px #fff, 865px 86px #fff, 988px 582px #fff, 1777px 1147px #fff,
    901px 1543px #fff, 728px 1994px #fff, 83px 1893px #fff, 266px 195px #fff,
    1545px 451px #fff, 311px 1659px #fff, 1612px 295px #fff, 1844px 651px #fff,
    955px 1670px #fff, 1631px 494px #fff, 1754px 435px #fff, 1271px 488px #fff,
    1859px 1892px #fff, 1631px 820px #fff, 1833px 1258px #fff, 316px 803px #fff,
    116px 1875px #fff, 1620px 1284px #fff, 1767px 1850px #fff, 18px 574px #fff,
    1857px 840px #fff, 184px 1498px #fff, 1389px 76px #fff, 27px 1974px #fff,
    1795px 463px #fff, 619px 1001px #fff, 93px 1857px #fff, 393px 1722px #fff,
    350px 396px #fff, 1789px 422px #fff, 1944px 1609px #fff, 24px 861px #fff,
    1895px 1470px #fff, 1478px 1168px #fff, 122px 296px #fff, 1100px 592px #fff,
    1561px 508px #fff, 572px 1016px #fff, 352px 94px #fff, 864px 1216px #fff,
    1036px 948px #fff, 1706px 1829px #fff, 143px 77px #fff, 1031px 1600px #fff,
    1028px 80px #fff, 1300px 1480px #fff, 1697px 1952px #fff, 1768px 1285px #fff,
    730px 693px #fff, 1759px 919px #fff, 917px 1127px #fff, 1752px 581px #fff,
    1614px 1205px #fff, 559px 1339px #fff, 1622px 255px #fff, 1700px 133px #fff,
    663px 625px #fff, 1854px 1606px #fff, 254px 581px #fff, 1597px 551px #fff,
    1092px 123px #fff, 788px 463px #fff, 1308px 531px #fff, 670px 569px #fff,
    487px 1212px #fff, 1181px 1323px #fff, 1499px 1757px #fff, 1632px 543px #fff,
    441px 1728px #fff, 838px 1005px #fff, 746px 252px #fff, 1000px 329px #fff,
    613px 1185px #fff, 1457px 61px #fff, 1849px 1012px #fff, 1210px 674px #fff,
    1138px 400px #fff, 1151px 1273px #fff, 537px 905px #fff, 317px 710px #fff,
    408px 1697px #fff, 1056px 1349px #fff, 794px 987px #fff, 400px 838px #fff,
    1667px 492px #fff, 942px 1739px #fff, 1085px 1870px #fff, 1899px 721px #fff,
    681px 1373px #fff, 798px 392px #fff, 486px 1259px #fff, 460px 687px #fff,
    959px 1491px #fff, 1821px 367px #fff, 730px 1532px #fff, 1202px 484px #fff,
    212px 1375px #fff, 111px 20px #fff, 917px 1009px #fff, 990px 813px #fff,
    470px 426px #fff, 1011px 753px #fff, 59px 461px #fff, 360px 1416px #fff,
    698px 1073px #fff, 1206px 55px #fff, 107px 1179px #fff, 1062px 1462px #fff,
    950px 1605px #fff, 1348px 1347px #fff, 1065px 287px #fff, 1425px 1861px #fff,
    1118px 480px #fff, 180px 1031px #fff, 1101px 1822px #fff, 1514px 1370px #fff,
    610px 1293px #fff, 397px 1510px #fff, 1975px 1389px #fff, 899px 1150px #fff,
    1292px 496px #fff, 1798px 1882px #fff, 1904px 1476px #fff, 802px 1795px #fff,
    1634px 1684px #fff, 743px 196px #fff, 1161px 963px #fff, 481px 821px #fff,
    1559px 1292px #fff, 871px 1822px #fff, 782px 560px #fff, 1576px 767px #fff,
    30px 1394px #fff, 1817px 863px #fff, 1783px 814px #fff, 20px 1976px #fff,
    988px 1586px #fff, 1670px 212px #fff, 1332px 1203px #fff, 311px 614px #fff,
    1562px 1617px #fff, 826px 1293px #fff, 283px 154px #fff, 1142px 1095px #fff,
    1188px 67px #fff, 8px 625px #fff, 1917px 1534px #fff, 1800px 677px #fff,
    620px 1687px #fff, 424px 192px #fff, 1205px 61px #fff, 704px 736px #fff,
    1966px 1835px #fff, 1539px 216px #fff, 1372px 750px #fff, 1947px 540px #fff,
    1839px 452px #fff, 1762px 695px #fff, 124px 588px #fff, 865px 1727px #fff,
    800px 1704px #fff, 18px 1066px #fff, 1441px 1500px #fff, 10px 236px #fff,
    1899px 594px #fff, 1607px 912px #fff, 25px 750px #fff, 628px 1612px #fff,
    1902px 663px #fff, 1863px 1708px #fff, 1608px 1013px #fff,
    1033px 1418px #fff, 1159px 1968px #fff, 1462px 338px #fff, 896px 123px #fff,
    1002px 1102px #fff, 1140px 180px #fff, 1039px 970px #fff, 1032px 1561px #fff,
    1783px 1206px #fff, 1993px 800px #fff, 909px 1938px #fff, 852px 388px #fff,
    704px 1369px #fff, 479px 1639px #fff, 172px 638px #fff, 590px 804px #fff,
    771px 1775px #fff, 794px 1040px #fff, 1972px 1781px #fff, 1313px 1733px #fff,
    499px 1018px #fff, 1437px 940px #fff, 939px 1735px #fff, 895px 1967px #fff,
    229px 204px #fff, 175px 401px #fff, 1094px 1186px #fff, 1813px 1864px #fff,
    980px 1516px #fff, 1311px 469px #fff, 1883px 1344px #fff, 449px 1582px #fff,
    1389px 1060px #fff, 1135px 1453px #fff, 1060px 1205px #fff,
    1010px 205px #fff, 973px 1888px #fff, 1429px 969px #fff, 89px 1710px #fff,
    1033px 1712px #fff, 1954px 818px #fff, 1497px 473px #fff, 161px 371px #fff,
    349px 1793px #fff, 491px 1316px #fff, 47px 1290px #fff, 1419px 1999px #fff,
    1248px 1790px #fff, 1269px 1237px #fff, 1398px 1005px #fff, 925px 926px #fff,
    1143px 1962px #fff, 1054px 361px #fff, 1752px 274px #fff, 1825px 1855px #fff,
    573px 191px #fff, 278px 657px #fff, 135px 516px #fff, 633px 1269px #fff,
    794px 384px #fff, 404px 977px #fff, 1715px 754px #fff, 266px 660px #fff,
    930px 1328px #fff, 711px 1828px #fff, 97px 1218px #fff, 133px 127px #fff,
    726px 1931px #fff, 426px 447px #fff, 1284px 1312px #fff, 871px 618px #fff,
    55px 1785px #fff, 1500px 1256px #fff, 648px 258px #fff, 1079px 156px #fff,
    1523px 857px #fff, 468px 1339px #fff, 1387px 1034px #fff, 470px 431px #fff,
    1390px 1700px #fff, 1699px 748px #fff, 1102px 74px #fff, 247px 950px #fff,
    249px 1010px #fff, 832px 135px #fff, 139px 1120px #fff, 789px 300px #fff,
    304px 1905px #fff, 1509px 1808px #fff, 657px 1940px #fff, 1864px 322px #fff,
    1948px 531px #fff, 624px 1164px #fff, 1565px 1673px #fff, 742px 1655px #fff,
    933px 674px #fff, 991px 1596px #fff, 1886px 654px #fff, 1657px 1656px #fff,
    458px 928px #fff, 1196px 1253px #fff, 1697px 197px #fff, 571px 1512px #fff,
    1891px 1658px #fff, 105px 1808px #fff, 1951px 27px #fff, 1234px 1326px #fff,
    1141px 242px #fff, 102px 886px #fff, 1227px 770px #fff, 1580px 392px #fff,
    1345px 709px #fff, 1325px 555px #fff, 562px 898px #fff, 1270px 479px #fff,
    1839px 884px #fff, 1908px 14px #fff, 1357px 595px #fff, 212px 297px #fff,
    647px 506px #fff, 1160px 1901px #fff, 1027px 1171px #fff, 1429px 1578px #fff,
    9px 618px #fff, 121px 1954px #fff, 1692px 164px #fff, 1083px 1457px #fff,
    1035px 742px #fff, 1163px 1566px #fff, 1144px 1385px #fff, 1211px 911px #fff,
    1653px 813px #fff, 1090px 1570px #fff, 290px 1271px #fff, 746px 1329px #fff,
    453px 1447px #fff, 1497px 1874px #fff, 1493px 1116px #fff, 845px 1742px #fff,
    1165px 1999px #fff, 576px 563px #fff, 51px 404px #fff, 993px 770px #fff,
    1997px 674px #fff, 1355px 1487px #fff, 1642px 146px #fff, 1160px 1718px #fff,
    947px 235px #fff, 874px 1198px #fff, 1280px 1465px #fff, 373px 654px #fff,
    1734px 608px #fff, 1699px 40px #fff, 122px 1568px #fff, 181px 1966px #fff,
    9px 194px #fff, 59px 707px #fff, 871px 726px #fff, 220px 11px #fff,
    1626px 1002px #fff, 877px 1749px #fff, 330px 300px #fff, 1515px 308px #fff,
    876px 1509px #fff, 1016px 960px #fff, 113px 267px #fff, 747px 1859px #fff,
    234px 906px #fff, 1687px 1601px #fff, 773px 844px #fff, 1812px 1479px #fff,
    688px 1103px #fff, 1305px 71px #fff, 1276px 493px #fff, 843px 1434px #fff,
    6px 1435px #fff, 1848px 508px #fff, 1101px 240px #fff, 1974px 1952px #fff,
    1072px 406px #fff, 697px 1196px #fff, 988px 256px #fff, 501px 582px #fff,
    460px 1825px #fff, 1912px 1029px #fff, 1829px 1728px #fff, 405px 190px #fff,
    1930px 1454px #fff, 1524px 1997px #fff, 721px 1573px #fff,
    1715px 1977px #fff, 1003px 452px #fff, 1200px 1844px #fff, 1532px 986px #fff,
    1631px 1912px #fff, 1521px 1236px #fff, 1862px 1939px #fff, 490px 259px #fff,
    991px 929px #fff, 1770px 96px #fff, 599px 1502px #fff, 1269px 1762px #fff,
    1745px 1873px #fff, 1462px 1727px #fff, 1271px 532px #fff,
    1544px 1172px #fff, 142px 1744px #fff, 521px 941px #fff, 596px 879px #fff,
    985px 427px #fff, 1691px 1653px #fff;
  animation: animStar 25s linear infinite;
}
#stars:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 1144px 728px #fff, 682px 735px #fff, 765px 1342px #fff,
    1098px 1189px #fff, 878px 1230px #fff, 754px 888px #fff, 1778px 1646px #fff,
    126px 1127px #fff, 1485px 23px #fff, 192px 1415px #fff, 488px 1489px #fff,
    539px 1578px #fff, 1720px 1531px #fff, 1031px 117px #fff, 1885px 1961px #fff,
    1212px 494px #fff, 149px 1298px #fff, 186px 1879px #fff, 914px 959px #fff,
    1948px 1384px #fff, 188px 1852px #fff, 1128px 1746px #fff,
    1486px 1885px #fff, 1608px 1075px #fff, 1252px 137px #fff, 270px 878px #fff,
    1802px 1559px #fff, 25px 1911px #fff, 1381px 293px #fff, 785px 357px #fff,
    1398px 1156px #fff, 1656px 338px #fff, 1551px 150px #fff, 1224px 536px #fff,
    585px 478px #fff, 1252px 1947px #fff, 1735px 365px #fff, 1671px 1871px #fff,
    1444px 1815px #fff, 1955px 1532px #fff, 1927px 168px #fff,
    1620px 1912px #fff, 1402px 1489px #fff, 54px 1247px #fff, 1143px 1189px #fff,
    698px 172px #fff, 1623px 1503px #fff, 485px 1533px #fff, 1560px 784px #fff,
    517px 950px #fff, 557px 201px #fff, 1959px 1370px #fff, 1744px 441px #fff,
    1678px 1171px #fff, 563px 875px #fff, 1013px 1615px #fff, 1018px 1582px #fff,
    1451px 20px #fff, 1526px 953px #fff, 1914px 1044px #fff, 1112px 48px #fff,
    1166px 64px #fff, 66px 1938px #fff, 1486px 998px #fff, 1759px 1964px #fff,
    1037px 1828px #fff, 465px 758px #fff, 1212px 176px #fff, 707px 157px #fff,
    1654px 1373px #fff, 1809px 334px #fff, 245px 1538px #fff, 1715px 462px #fff,
    1154px 1697px #fff, 1243px 331px #fff, 397px 861px #fff, 108px 1501px #fff,
    874px 133px #fff, 1822px 34px #fff, 1107px 1417px #fff, 143px 1763px #fff,
    1837px 1358px #fff, 318px 1139px #fff, 397px 1747px #fff, 1010px 772px #fff,
    92px 321px #fff, 1121px 183px #fff, 765px 1658px #fff, 1139px 52px #fff,
    1768px 1431px #fff, 358px 699px #fff, 583px 148px #fff, 375px 1002px #fff,
    1276px 231px #fff, 784px 214px #fff, 578px 944px #fff, 1135px 1543px #fff,
    334px 651px #fff, 1554px 889px #fff, 167px 1716px #fff, 1961px 688px #fff,
    986px 1801px #fff, 1994px 1859px #fff, 1631px 800px #fff, 435px 238px #fff,
    1707px 128px #fff, 1018px 612px #fff, 964px 1357px #fff, 96px 1730px #fff,
    433px 428px #fff, 950px 1456px #fff, 494px 885px #fff, 916px 1578px #fff,
    1547px 404px #fff, 1806px 1533px #fff, 1891px 166px #fff, 1761px 986px #fff,
    1093px 839px #fff, 112px 577px #fff, 188px 957px #fff, 423px 400px #fff,
    1949px 1424px #fff, 1502px 230px #fff, 832px 632px #fff, 690px 1107px #fff,
    1054px 1296px #fff, 1407px 1882px #fff, 1330px 1142px #fff, 689px 964px #fff,
    752px 434px #fff, 761px 1222px #fff, 1968px 46px #fff, 1449px 101px #fff,
    936px 832px #fff, 593px 1074px #fff, 1238px 729px #fff, 329px 1630px #fff,
    1609px 1742px #fff, 1613px 1335px #fff, 1187px 1238px #fff,
    1827px 1116px #fff, 720px 1162px #fff, 1795px 1548px #fff,
    1795px 1768px #fff, 600px 1144px #fff, 1799px 935px #fff, 887px 1814px #fff,
    1525px 1307px #fff, 1252px 134px #fff, 314px 68px #fff, 1057px 634px #fff,
    841px 1673px #fff, 1677px 923px #fff, 724px 1006px #fff, 449px 1909px #fff,
    1561px 733px #fff, 787px 460px #fff, 952px 1574px #fff, 965px 1854px #fff,
    12px 1819px #fff, 1771px 509px #fff, 998px 1745px #fff, 1158px 1713px #fff,
    287px 425px #fff, 1097px 13px #fff, 475px 1837px #fff, 1950px 1870px #fff,
    923px 310px #fff, 1883px 1308px #fff, 148px 1811px #fff, 1974px 878px #fff,
    371px 720px #fff, 201px 1137px #fff, 1069px 1619px #fff, 573px 874px #fff,
    1105px 1215px #fff, 1143px 750px #fff, 1356px 1999px #fff, 695px 1271px #fff,
    560px 1585px #fff, 562px 1185px #fff, 876px 888px #fff, 1124px 11px #fff,
    1127px 1512px #fff, 1615px 361px #fff, 1032px 295px #fff, 1100px 652px #fff,
    1768px 747px #fff, 1952px 288px #fff, 866px 1392px #fff, 1517px 1538px #fff,
    544px 1270px #fff, 645px 679px #fff, 1499px 662px #fff, 1046px 982px #fff,
    54px 883px #fff, 1583px 381px #fff, 1076px 944px #fff, 65px 1077px #fff,
    251px 1478px #fff, 518px 1936px #fff, 72px 1112px #fff, 1906px 1663px #fff,
    996px 442px #fff, 1330px 419px #fff, 1157px 1529px #fff, 1476px 1037px #fff,
    855px 83px #fff, 808px 399px #fff, 1952px 1680px #fff, 445px 84px #fff,
    977px 739px #fff, 1866px 458px #fff, 230px 293px #fff, 1414px 555px #fff,
    292px 989px #fff, 1651px 1518px #fff, 1362px 1511px #fff, 471px 862px #fff,
    383px 993px #fff, 685px 546px #fff, 1908px 1431px #fff, 1030px 107px #fff,
    523px 141px #fff, 1734px 1409px #fff, 824px 991px #fff, 747px 1326px #fff,
    1259px 1334px #fff, 805px 760px #fff, 308px 1130px #fff, 1511px 729px #fff,
    137px 725px #fff, 1551px 893px #fff, 1177px 154px #fff, 1846px 1661px #fff,
    484px 1096px #fff, 1691px 1521px #fff, 1598px 1910px #fff, 846px 468px #fff,
    1739px 1771px #fff, 893px 945px #fff, 831px 407px #fff, 404px 310px #fff,
    232px 1317px #fff, 1236px 1696px #fff, 1771px 457px #fff, 407px 1169px #fff,
    74px 1125px #fff, 1376px 1865px #fff, 44px 836px #fff, 1948px 595px #fff,
    188px 1258px #fff, 35px 1501px #fff, 629px 1069px #fff, 1603px 931px #fff,
    489px 862px #fff, 1181px 1986px #fff, 1866px 1444px #fff, 1537px 303px #fff,
    1952px 17px #fff, 13px 1240px #fff, 1307px 911px #fff, 1543px 1135px #fff,
    1101px 1278px #fff, 1212px 1077px #fff, 1798px 1155px #fff,
    1464px 342px #fff, 553px 1623px #fff, 207px 395px #fff, 683px 424px #fff,
    586px 1770px #fff, 1265px 220px #fff, 357px 1815px #fff, 780px 1184px #fff,
    877px 1904px #fff, 326px 1569px #fff, 1819px 1938px #fff, 1839px 503px #fff,
    720px 1231px #fff, 640px 1084px #fff, 1122px 657px #fff, 725px 945px #fff,
    992px 816px #fff, 1935px 851px #fff, 1074px 1686px #fff, 1160px 1438px #fff,
    1259px 1905px #fff, 1120px 215px #fff, 92px 581px #fff, 932px 367px #fff,
    1121px 523px #fff, 1273px 1866px #fff, 149px 140px #fff, 230px 1212px #fff,
    1265px 1592px #fff, 1223px 654px #fff, 820px 1316px #fff, 1369px 1946px #fff,
    319px 1164px #fff, 1058px 1973px #fff, 1756px 41px #fff, 740px 2000px #fff,
    1241px 1914px #fff, 248px 171px #fff, 1657px 1915px #fff, 1662px 260px #fff,
    1670px 736px #fff, 1877px 607px #fff, 396px 1058px #fff, 240px 599px #fff,
    769px 1041px #fff, 940px 1436px #fff, 793px 632px #fff, 1350px 430px #fff,
    1790px 1327px #fff, 865px 86px #fff, 988px 582px #fff, 1777px 1147px #fff,
    901px 1543px #fff, 728px 1994px #fff, 83px 1893px #fff, 266px 195px #fff,
    1545px 451px #fff, 311px 1659px #fff, 1612px 295px #fff, 1844px 651px #fff,
    955px 1670px #fff, 1631px 494px #fff, 1754px 435px #fff, 1271px 488px #fff,
    1859px 1892px #fff, 1631px 820px #fff, 1833px 1258px #fff, 316px 803px #fff,
    116px 1875px #fff, 1620px 1284px #fff, 1767px 1850px #fff, 18px 574px #fff,
    1857px 840px #fff, 184px 1498px #fff, 1389px 76px #fff, 27px 1974px #fff,
    1795px 463px #fff, 619px 1001px #fff, 93px 1857px #fff, 393px 1722px #fff,
    350px 396px #fff, 1789px 422px #fff, 1944px 1609px #fff, 24px 861px #fff,
    1895px 1470px #fff, 1478px 1168px #fff, 122px 296px #fff, 1100px 592px #fff,
    1561px 508px #fff, 572px 1016px #fff, 352px 94px #fff, 864px 1216px #fff,
    1036px 948px #fff, 1706px 1829px #fff, 143px 77px #fff, 1031px 1600px #fff,
    1028px 80px #fff, 1300px 1480px #fff, 1697px 1952px #fff, 1768px 1285px #fff,
    730px 693px #fff, 1759px 919px #fff, 917px 1127px #fff, 1752px 581px #fff,
    1614px 1205px #fff, 559px 1339px #fff, 1622px 255px #fff, 1700px 133px #fff,
    663px 625px #fff, 1854px 1606px #fff, 254px 581px #fff, 1597px 551px #fff,
    1092px 123px #fff, 788px 463px #fff, 1308px 531px #fff, 670px 569px #fff,
    487px 1212px #fff, 1181px 1323px #fff, 1499px 1757px #fff, 1632px 543px #fff,
    441px 1728px #fff, 838px 1005px #fff, 746px 252px #fff, 1000px 329px #fff,
    613px 1185px #fff, 1457px 61px #fff, 1849px 1012px #fff, 1210px 674px #fff,
    1138px 400px #fff, 1151px 1273px #fff, 537px 905px #fff, 317px 710px #fff,
    408px 1697px #fff, 1056px 1349px #fff, 794px 987px #fff, 400px 838px #fff,
    1667px 492px #fff, 942px 1739px #fff, 1085px 1870px #fff, 1899px 721px #fff,
    681px 1373px #fff, 798px 392px #fff, 486px 1259px #fff, 460px 687px #fff,
    959px 1491px #fff, 1821px 367px #fff, 730px 1532px #fff, 1202px 484px #fff,
    212px 1375px #fff, 111px 20px #fff, 917px 1009px #fff, 990px 813px #fff,
    470px 426px #fff, 1011px 753px #fff, 59px 461px #fff, 360px 1416px #fff,
    698px 1073px #fff, 1206px 55px #fff, 107px 1179px #fff, 1062px 1462px #fff,
    950px 1605px #fff, 1348px 1347px #fff, 1065px 287px #fff, 1425px 1861px #fff,
    1118px 480px #fff, 180px 1031px #fff, 1101px 1822px #fff, 1514px 1370px #fff,
    610px 1293px #fff, 397px 1510px #fff, 1975px 1389px #fff, 899px 1150px #fff,
    1292px 496px #fff, 1798px 1882px #fff, 1904px 1476px #fff, 802px 1795px #fff,
    1634px 1684px #fff, 743px 196px #fff, 1161px 963px #fff, 481px 821px #fff,
    1559px 1292px #fff, 871px 1822px #fff, 782px 560px #fff, 1576px 767px #fff,
    30px 1394px #fff, 1817px 863px #fff, 1783px 814px #fff, 20px 1976px #fff,
    988px 1586px #fff, 1670px 212px #fff, 1332px 1203px #fff, 311px 614px #fff,
    1562px 1617px #fff, 826px 1293px #fff, 283px 154px #fff, 1142px 1095px #fff,
    1188px 67px #fff, 8px 625px #fff, 1917px 1534px #fff, 1800px 677px #fff,
    620px 1687px #fff, 424px 192px #fff, 1205px 61px #fff, 704px 736px #fff,
    1966px 1835px #fff, 1539px 216px #fff, 1372px 750px #fff, 1947px 540px #fff,
    1839px 452px #fff, 1762px 695px #fff, 124px 588px #fff, 865px 1727px #fff,
    800px 1704px #fff, 18px 1066px #fff, 1441px 1500px #fff, 10px 236px #fff,
    1899px 594px #fff, 1607px 912px #fff, 25px 750px #fff, 628px 1612px #fff,
    1902px 663px #fff, 1863px 1708px #fff, 1608px 1013px #fff,
    1033px 1418px #fff, 1159px 1968px #fff, 1462px 338px #fff, 896px 123px #fff,
    1002px 1102px #fff, 1140px 180px #fff, 1039px 970px #fff, 1032px 1561px #fff,
    1783px 1206px #fff, 1993px 800px #fff, 909px 1938px #fff, 852px 388px #fff,
    704px 1369px #fff, 479px 1639px #fff, 172px 638px #fff, 590px 804px #fff,
    771px 1775px #fff, 794px 1040px #fff, 1972px 1781px #fff, 1313px 1733px #fff,
    499px 1018px #fff, 1437px 940px #fff, 939px 1735px #fff, 895px 1967px #fff,
    229px 204px #fff, 175px 401px #fff, 1094px 1186px #fff, 1813px 1864px #fff,
    980px 1516px #fff, 1311px 469px #fff, 1883px 1344px #fff, 449px 1582px #fff,
    1389px 1060px #fff, 1135px 1453px #fff, 1060px 1205px #fff,
    1010px 205px #fff, 973px 1888px #fff, 1429px 969px #fff, 89px 1710px #fff,
    1033px 1712px #fff, 1954px 818px #fff, 1497px 473px #fff, 161px 371px #fff,
    349px 1793px #fff, 491px 1316px #fff, 47px 1290px #fff, 1419px 1999px #fff,
    1248px 1790px #fff, 1269px 1237px #fff, 1398px 1005px #fff, 925px 926px #fff,
    1143px 1962px #fff, 1054px 361px #fff, 1752px 274px #fff, 1825px 1855px #fff,
    573px 191px #fff, 278px 657px #fff, 135px 516px #fff, 633px 1269px #fff,
    794px 384px #fff, 404px 977px #fff, 1715px 754px #fff, 266px 660px #fff,
    930px 1328px #fff, 711px 1828px #fff, 97px 1218px #fff, 133px 127px #fff,
    726px 1931px #fff, 426px 447px #fff, 1284px 1312px #fff, 871px 618px #fff,
    55px 1785px #fff, 1500px 1256px #fff, 648px 258px #fff, 1079px 156px #fff,
    1523px 857px #fff, 468px 1339px #fff, 1387px 1034px #fff, 470px 431px #fff,
    1390px 1700px #fff, 1699px 748px #fff, 1102px 74px #fff, 247px 950px #fff,
    249px 1010px #fff, 832px 135px #fff, 139px 1120px #fff, 789px 300px #fff,
    304px 1905px #fff, 1509px 1808px #fff, 657px 1940px #fff, 1864px 322px #fff,
    1948px 531px #fff, 624px 1164px #fff, 1565px 1673px #fff, 742px 1655px #fff,
    933px 674px #fff, 991px 1596px #fff, 1886px 654px #fff, 1657px 1656px #fff,
    458px 928px #fff, 1196px 1253px #fff, 1697px 197px #fff, 571px 1512px #fff,
    1891px 1658px #fff, 105px 1808px #fff, 1951px 27px #fff, 1234px 1326px #fff,
    1141px 242px #fff, 102px 886px #fff, 1227px 770px #fff, 1580px 392px #fff,
    1345px 709px #fff, 1325px 555px #fff, 562px 898px #fff, 1270px 479px #fff,
    1839px 884px #fff, 1908px 14px #fff, 1357px 595px #fff, 212px 297px #fff,
    647px 506px #fff, 1160px 1901px #fff, 1027px 1171px #fff, 1429px 1578px #fff,
    9px 618px #fff, 121px 1954px #fff, 1692px 164px #fff, 1083px 1457px #fff,
    1035px 742px #fff, 1163px 1566px #fff, 1144px 1385px #fff, 1211px 911px #fff,
    1653px 813px #fff, 1090px 1570px #fff, 290px 1271px #fff, 746px 1329px #fff,
    453px 1447px #fff, 1497px 1874px #fff, 1493px 1116px #fff, 845px 1742px #fff,
    1165px 1999px #fff, 576px 563px #fff, 51px 404px #fff, 993px 770px #fff,
    1997px 674px #fff, 1355px 1487px #fff, 1642px 146px #fff, 1160px 1718px #fff,
    947px 235px #fff, 874px 1198px #fff, 1280px 1465px #fff, 373px 654px #fff,
    1734px 608px #fff, 1699px 40px #fff, 122px 1568px #fff, 181px 1966px #fff,
    9px 194px #fff, 59px 707px #fff, 871px 726px #fff, 220px 11px #fff,
    1626px 1002px #fff, 877px 1749px #fff, 330px 300px #fff, 1515px 308px #fff,
    876px 1509px #fff, 1016px 960px #fff, 113px 267px #fff, 747px 1859px #fff,
    234px 906px #fff, 1687px 1601px #fff, 773px 844px #fff, 1812px 1479px #fff,
    688px 1103px #fff, 1305px 71px #fff, 1276px 493px #fff, 843px 1434px #fff,
    6px 1435px #fff, 1848px 508px #fff, 1101px 240px #fff, 1974px 1952px #fff,
    1072px 406px #fff, 697px 1196px #fff, 988px 256px #fff, 501px 582px #fff,
    460px 1825px #fff, 1912px 1029px #fff, 1829px 1728px #fff, 405px 190px #fff,
    1930px 1454px #fff, 1524px 1997px #fff, 721px 1573px #fff,
    1715px 1977px #fff, 1003px 452px #fff, 1200px 1844px #fff, 1532px 986px #fff,
    1631px 1912px #fff, 1521px 1236px #fff, 1862px 1939px #fff, 490px 259px #fff,
    991px 929px #fff, 1770px 96px #fff, 599px 1502px #fff, 1269px 1762px #fff,
    1745px 1873px #fff, 1462px 1727px #fff, 1271px 532px #fff,
    1544px 1172px #fff, 142px 1744px #fff, 521px 941px #fff, 596px 879px #fff,
    985px 427px #fff, 1691px 1653px #fff;
}
#stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 1840px 921px #fff, 782px 1464px #fff, 915px 948px #fff,
    205px 1621px #fff, 494px 1474px #fff, 884px 432px #fff, 754px 1413px #fff,
    1295px 1139px #fff, 731px 1000px #fff, 110px 165px #fff, 1909px 1819px #fff,
    664px 1644px #fff, 934px 1356px #fff, 1285px 1092px #fff, 1644px 1772px #fff,
    1720px 2px #fff, 959px 1515px #fff, 1559px 1293px #fff, 1983px 1591px #fff,
    1739px 1373px #fff, 1397px 1801px #fff, 167px 506px #fff, 1580px 860px #fff,
    1963px 1478px #fff, 403px 860px #fff, 1126px 451px #fff, 886px 237px #fff,
    1233px 729px #fff, 1724px 843px #fff, 1695px 1257px #fff, 1353px 322px #fff,
    715px 484px #fff, 589px 952px #fff, 1099px 1406px #fff, 1766px 914px #fff,
    393px 106px #fff, 509px 1044px #fff, 1550px 1583px #fff, 559px 185px #fff,
    1976px 274px #fff, 148px 1832px #fff, 1157px 403px #fff, 45px 1276px #fff,
    340px 1037px #fff, 1349px 854px #fff, 1183px 44px #fff, 1238px 34px #fff,
    1397px 1014px #fff, 427px 847px #fff, 1300px 705px #fff, 1320px 605px #fff,
    295px 39px #fff, 732px 1926px #fff, 767px 1117px #fff, 1047px 1642px #fff,
    860px 658px #fff, 174px 1905px #fff, 1287px 1413px #fff, 894px 1692px #fff,
    105px 1072px #fff, 610px 938px #fff, 156px 509px #fff, 1160px 1231px #fff,
    1561px 1342px #fff, 1217px 1246px #fff, 1878px 114px #fff,
    1992px 1771px #fff, 227px 499px #fff, 648px 502px #fff, 1691px 1690px #fff,
    1299px 935px #fff, 1143px 1155px #fff, 1499px 972px #fff, 531px 1008px #fff,
    611px 741px #fff, 1358px 986px #fff, 933px 1494px #fff, 1135px 744px #fff,
    1546px 1936px #fff, 974px 290px #fff, 1319px 1210px #fff, 791px 735px #fff,
    198px 73px #fff, 330px 1378px #fff, 81px 637px #fff, 347px 758px #fff,
    1059px 1818px #fff, 1731px 1363px #fff, 1305px 45px #fff, 1693px 226px #fff,
    141px 1576px #fff, 1119px 1694px #fff, 1872px 384px #fff, 364px 1057px #fff,
    1267px 485px #fff, 1587px 1641px #fff, 1817px 1487px #fff, 871px 613px #fff,
    801px 1945px #fff, 1306px 1551px #fff, 1413px 72px #fff, 414px 1792px #fff,
    211px 260px #fff, 1481px 1903px #fff, 117px 18px #fff, 499px 543px #fff,
    1952px 638px #fff, 828px 202px #fff, 1923px 796px #fff, 308px 1151px #fff,
    1430px 611px #fff, 135px 1591px #fff, 268px 1131px #fff, 1729px 1736px #fff,
    353px 82px #fff, 307px 576px #fff, 943px 1105px #fff, 1388px 1408px #fff,
    51px 77px #fff, 206px 1469px #fff, 1575px 307px #fff, 1166px 1445px #fff,
    819px 1288px #fff, 908px 850px #fff, 1840px 1467px #fff, 176px 366px #fff,
    448px 1817px #fff, 1729px 279px #fff, 189px 1446px #fff, 1407px 1899px #fff,
    950px 789px #fff, 1618px 1294px #fff, 227px 353px #fff, 1481px 1774px #fff,
    955px 649px #fff, 1792px 1445px #fff, 1667px 794px #fff, 1468px 1834px #fff,
    1407px 1645px #fff, 762px 1683px #fff, 785px 1314px #fff, 1231px 468px #fff,
    1125px 1751px #fff, 1503px 1046px #fff, 390px 1813px #fff,
    1509px 1619px #fff, 657px 76px #fff, 1731px 1530px #fff, 1542px 1692px #fff,
    1109px 217px #fff, 1548px 900px #fff, 1716px 531px #fff, 1533px 839px #fff,
    1196px 42px #fff, 1553px 738px #fff, 936px 526px #fff, 766px 356px #fff,
    873px 1371px #fff, 439px 341px #fff, 1061px 607px #fff, 1635px 548px #fff,
    1138px 1993px #fff, 1388px 1231px #fff, 186px 785px #fff, 1063px 360px #fff,
    1068px 501px #fff, 1561px 903px #fff, 1403px 517px #fff, 1775px 1636px #fff,
    388px 67px #fff, 1568px 1149px #fff, 116px 837px #fff, 1426px 27px #fff,
    436px 537px #fff, 872px 1121px #fff, 73px 937px #fff, 690px 302px #fff,
    981px 188px #fff, 1638px 1256px #fff, 168px 1940px #fff, 1702px 55px #fff,
    1911px 1877px #fff, 1349px 1203px #fff, 1648px 1902px #fff,
    611px 1033px #fff, 1206px 1212px #fff, 583px 1070px #fff, 629px 501px #fff,
    1150px 579px #fff, 629px 1929px #fff, 941px 225px #fff, 72px 446px #fff,
    1964px 69px #fff, 1498px 863px #fff, 1912px 331px #fff, 594px 1870px #fff,
    1538px 1284px #fff, 1744px 1288px #fff, 1850px 1172px #fff,
    1688px 327px #fff;
  animation: animStar 50s linear infinite;
}
#stars2:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 1840px 921px #fff, 782px 1464px #fff, 915px 948px #fff,
    205px 1621px #fff, 494px 1474px #fff, 884px 432px #fff, 754px 1413px #fff,
    1295px 1139px #fff, 731px 1000px #fff, 110px 165px #fff, 1909px 1819px #fff,
    664px 1644px #fff, 934px 1356px #fff, 1285px 1092px #fff, 1644px 1772px #fff,
    1720px 2px #fff, 959px 1515px #fff, 1559px 1293px #fff, 1983px 1591px #fff,
    1739px 1373px #fff, 1397px 1801px #fff, 167px 506px #fff, 1580px 860px #fff,
    1963px 1478px #fff, 403px 860px #fff, 1126px 451px #fff, 886px 237px #fff,
    1233px 729px #fff, 1724px 843px #fff, 1695px 1257px #fff, 1353px 322px #fff,
    715px 484px #fff, 589px 952px #fff, 1099px 1406px #fff, 1766px 914px #fff,
    393px 106px #fff, 509px 1044px #fff, 1550px 1583px #fff, 559px 185px #fff,
    1976px 274px #fff, 148px 1832px #fff, 1157px 403px #fff, 45px 1276px #fff,
    340px 1037px #fff, 1349px 854px #fff, 1183px 44px #fff, 1238px 34px #fff,
    1397px 1014px #fff, 427px 847px #fff, 1300px 705px #fff, 1320px 605px #fff,
    295px 39px #fff, 732px 1926px #fff, 767px 1117px #fff, 1047px 1642px #fff,
    860px 658px #fff, 174px 1905px #fff, 1287px 1413px #fff, 894px 1692px #fff,
    105px 1072px #fff, 610px 938px #fff, 156px 509px #fff, 1160px 1231px #fff,
    1561px 1342px #fff, 1217px 1246px #fff, 1878px 114px #fff,
    1992px 1771px #fff, 227px 499px #fff, 648px 502px #fff, 1691px 1690px #fff,
    1299px 935px #fff, 1143px 1155px #fff, 1499px 972px #fff, 531px 1008px #fff,
    611px 741px #fff, 1358px 986px #fff, 933px 1494px #fff, 1135px 744px #fff,
    1546px 1936px #fff, 974px 290px #fff, 1319px 1210px #fff, 791px 735px #fff,
    198px 73px #fff, 330px 1378px #fff, 81px 637px #fff, 347px 758px #fff,
    1059px 1818px #fff, 1731px 1363px #fff, 1305px 45px #fff, 1693px 226px #fff,
    141px 1576px #fff, 1119px 1694px #fff, 1872px 384px #fff, 364px 1057px #fff,
    1267px 485px #fff, 1587px 1641px #fff, 1817px 1487px #fff, 871px 613px #fff,
    801px 1945px #fff, 1306px 1551px #fff, 1413px 72px #fff, 414px 1792px #fff,
    211px 260px #fff, 1481px 1903px #fff, 117px 18px #fff, 499px 543px #fff,
    1952px 638px #fff, 828px 202px #fff, 1923px 796px #fff, 308px 1151px #fff,
    1430px 611px #fff, 135px 1591px #fff, 268px 1131px #fff, 1729px 1736px #fff,
    353px 82px #fff, 307px 576px #fff, 943px 1105px #fff, 1388px 1408px #fff,
    51px 77px #fff, 206px 1469px #fff, 1575px 307px #fff, 1166px 1445px #fff,
    819px 1288px #fff, 908px 850px #fff, 1840px 1467px #fff, 176px 366px #fff,
    448px 1817px #fff, 1729px 279px #fff, 189px 1446px #fff, 1407px 1899px #fff,
    950px 789px #fff, 1618px 1294px #fff, 227px 353px #fff, 1481px 1774px #fff,
    955px 649px #fff, 1792px 1445px #fff, 1667px 794px #fff, 1468px 1834px #fff,
    1407px 1645px #fff, 762px 1683px #fff, 785px 1314px #fff, 1231px 468px #fff,
    1125px 1751px #fff, 1503px 1046px #fff, 390px 1813px #fff,
    1509px 1619px #fff, 657px 76px #fff, 1731px 1530px #fff, 1542px 1692px #fff,
    1109px 217px #fff, 1548px 900px #fff, 1716px 531px #fff, 1533px 839px #fff,
    1196px 42px #fff, 1553px 738px #fff, 936px 526px #fff, 766px 356px #fff,
    873px 1371px #fff, 439px 341px #fff, 1061px 607px #fff, 1635px 548px #fff,
    1138px 1993px #fff, 1388px 1231px #fff, 186px 785px #fff, 1063px 360px #fff,
    1068px 501px #fff, 1561px 903px #fff, 1403px 517px #fff, 1775px 1636px #fff,
    388px 67px #fff, 1568px 1149px #fff, 116px 837px #fff, 1426px 27px #fff,
    436px 537px #fff, 872px 1121px #fff, 73px 937px #fff, 690px 302px #fff,
    981px 188px #fff, 1638px 1256px #fff, 168px 1940px #fff, 1702px 55px #fff,
    1911px 1877px #fff, 1349px 1203px #fff, 1648px 1902px #fff,
    611px 1033px #fff, 1206px 1212px #fff, 583px 1070px #fff, 629px 501px #fff,
    1150px 579px #fff, 629px 1929px #fff, 941px 225px #fff, 72px 446px #fff,
    1964px 69px #fff, 1498px 863px #fff, 1912px 331px #fff, 594px 1870px #fff,
    1538px 1284px #fff, 1744px 1288px #fff, 1850px 1172px #fff,
    1688px 327px #fff;
}
#stars3 {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 1360px 1075px #fff, 1012px 365px #fff, 1613px 679px #fff,
    1254px 492px #fff, 1569px 612px #fff, 1344px 1408px #fff, 292px 1890px #fff,
    883px 1678px #fff, 1589px 1585px #fff, 282px 822px #fff, 765px 453px #fff,
    1475px 1887px #fff, 1779px 824px #fff, 833px 425px #fff, 1308px 1311px #fff,
    1167px 816px #fff, 813px 68px #fff, 42px 559px #fff, 248px 487px #fff,
    547px 1033px #fff, 1672px 1578px #fff, 1824px 1714px #fff, 1140px 63px #fff,
    884px 441px #fff, 427px 843px #fff, 184px 657px #fff, 1796px 1296px #fff,
    37px 242px #fff, 836px 1324px #fff, 555px 1410px #fff, 1759px 230px #fff,
    1680px 1130px #fff, 319px 443px #fff, 1636px 444px #fff, 612px 1327px #fff,
    652px 1229px #fff, 1623px 1181px #fff, 862px 1749px #fff, 1552px 1262px #fff,
    695px 472px #fff, 1816px 1500px #fff, 463px 632px #fff, 314px 1335px #fff,
    571px 1029px #fff, 366px 771px #fff, 214px 1283px #fff, 763px 81px #fff,
    294px 1445px #fff, 238px 788px #fff, 1203px 687px #fff, 1278px 1892px #fff,
    1116px 1736px #fff, 920px 790px #fff, 1098px 374px #fff, 628px 622px #fff,
    747px 1054px #fff, 669px 1651px #fff, 255px 1825px #fff, 824px 733px #fff,
    714px 158px #fff, 1535px 1718px #fff, 1px 186px #fff, 1811px 654px #fff,
    253px 167px #fff, 1844px 24px #fff, 81px 1236px #fff, 1862px 225px #fff,
    234px 396px #fff, 803px 719px #fff, 1428px 1242px #fff, 1763px 1169px #fff,
    793px 1104px #fff, 473px 748px #fff, 1253px 1104px #fff, 1760px 703px #fff,
    715px 428px #fff, 887px 564px #fff, 800px 1531px #fff, 76px 1330px #fff,
    816px 1968px #fff, 940px 1413px #fff, 936px 871px #fff, 1415px 1257px #fff,
    1595px 1042px #fff, 910px 248px #fff, 519px 353px #fff, 1414px 1334px #fff,
    1167px 242px #fff, 535px 654px #fff, 492px 1553px #fff, 1340px 868px #fff,
    874px 110px #fff, 1729px 109px #fff, 36px 1466px #fff, 137px 475px #fff,
    622px 1917px #fff, 977px 525px #fff, 1957px 1364px #fff, 352px 1327px #fff,
    746px 1922px #fff;
  animation: animStar 75s linear infinite;
}
#stars3:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 1360px 1075px #fff, 1012px 365px #fff, 1613px 679px #fff,
    1254px 492px #fff, 1569px 612px #fff, 1344px 1408px #fff, 292px 1890px #fff,
    883px 1678px #fff, 1589px 1585px #fff, 282px 822px #fff, 765px 453px #fff,
    1475px 1887px #fff, 1779px 824px #fff, 833px 425px #fff, 1308px 1311px #fff,
    1167px 816px #fff, 813px 68px #fff, 42px 559px #fff, 248px 487px #fff,
    547px 1033px #fff, 1672px 1578px #fff, 1824px 1714px #fff, 1140px 63px #fff,
    884px 441px #fff, 427px 843px #fff, 184px 657px #fff, 1796px 1296px #fff,
    37px 242px #fff, 836px 1324px #fff, 555px 1410px #fff, 1759px 230px #fff,
    1680px 1130px #fff, 319px 443px #fff, 1636px 444px #fff, 612px 1327px #fff,
    652px 1229px #fff, 1623px 1181px #fff, 862px 1749px #fff, 1552px 1262px #fff,
    695px 472px #fff, 1816px 1500px #fff, 463px 632px #fff, 314px 1335px #fff,
    571px 1029px #fff, 366px 771px #fff, 214px 1283px #fff, 763px 81px #fff,
    294px 1445px #fff, 238px 788px #fff, 1203px 687px #fff, 1278px 1892px #fff,
    1116px 1736px #fff, 920px 790px #fff, 1098px 374px #fff, 628px 622px #fff,
    747px 1054px #fff, 669px 1651px #fff, 255px 1825px #fff, 824px 733px #fff,
    714px 158px #fff, 1535px 1718px #fff, 1px 186px #fff, 1811px 654px #fff,
    253px 167px #fff, 1844px 24px #fff, 81px 1236px #fff, 1862px 225px #fff,
    234px 396px #fff, 803px 719px #fff, 1428px 1242px #fff, 1763px 1169px #fff,
    793px 1104px #fff, 473px 748px #fff, 1253px 1104px #fff, 1760px 703px #fff,
    715px 428px #fff, 887px 564px #fff, 800px 1531px #fff, 76px 1330px #fff,
    816px 1968px #fff, 940px 1413px #fff, 936px 871px #fff, 1415px 1257px #fff,
    1595px 1042px #fff, 910px 248px #fff, 519px 353px #fff, 1414px 1334px #fff,
    1167px 242px #fff, 535px 654px #fff, 492px 1553px #fff, 1340px 868px #fff,
    874px 110px #fff, 1729px 109px #fff, 36px 1466px #fff, 137px 475px #fff,
    622px 1917px #fff, 977px 525px #fff, 1957px 1364px #fff, 352px 1327px #fff,
    746px 1922px #fff;
}
@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}
